import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import BlogFeed from "../components/blog-feed";
import Layout from "../components/layout";
import Seo from "../components/seo"
import Hero from "../components/hero";
import CTA from "../components/cta";
import NeedAQuote from "../components/need-a-quote";
import Quote from "../components/quote";
import patternHex from "../images/backrounds/pattern-hex-white.jpg";
import newRelicLogo from "../images/partners/new-relic/logo.png"
import newRelicWhiteLogo from "../images/partners/new-relic/logo-white.png"

const Index = ({ location }) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Partnerships New Relic"
        description="As a New Relic partner, we can help you customizem set up and and configure your instance so you can get the most of the product."
      />
      <Hero bgImage={patternHex}>
        <Hero.Inner>
          <Hero.Image
            src={newRelicLogo}
            srcAlt={newRelicWhiteLogo}
            alt="New Relic Logo"
          />
          <Hero.Content>
            <Hero.Title>Make the most of New Relic</Hero.Title>
            <Hero.Description>
              <p className="text-white text-center text-md-left">
                As a certified systems integrations partner to New Relic, we can
                help you make the most of your monitoring tool to improve app,
                database, and server performance. 729 can help you reduce
                bottlenecks, downtime and predict issues.
              </p>
            </Hero.Description>
            <Hero.CallToAction>
              <CTA
                href="/contact-us"
                text="Tell us about your project"
                className="btn btn-secondary-light"
              />
            </Hero.CallToAction>
          </Hero.Content>
        </Hero.Inner>
      </Hero>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 my-4">
              <h2>Migration to New Relic</h2>
              <p>
                Setting up a new monitoring tool? Moving from another system
                like Datadog or AppD? No matter what your need 729 can help you
                set things up right from the start.
              </p>
              <p>
                If you have data and code on any other monitoring tool 729 can
                help you replace it with New Relic and set up matching charts
                and dashboards. As a certified New Relic partner, we will bring
                best practices from the start with industry-specific
                visualization.
              </p>
            </div>
            <div className="col-12 col-md-6 my-4">
              <h2>Configuration And Instrumentation</h2>
              <p>
                729 can help you set up New Relic so you are set up for success.
              </p>
              <p>
                The best way to get key insights about your development and
                infrastructure performance is to customize it right for your
                business.
              </p>
              <ul className="text-primary">
                <li>
                  <span className="text-dark">
                    Collect the right custom attributes
                  </span>
                </li>
                <li>
                  <span className="text-dark">Set up your KPIs</span>
                </li>
                <li>
                  <span className="text-dark">Learn about user behavior</span>
                </li>
                <li>
                  <span className="text-dark">
                    Watch your business through your tech stack
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Watch for performance issues
                  </span>
                </li>
                <li>
                  <span className="text-dark">
                    Find bottlenecks and improve efficiency
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 my-4">
              <h2>Optimization</h2>
              <p>
                Learn to ship better software, run smoother DevOps processes and
                handle scalable loads. 729 will review your entire stack from
                code to cloud and help you plan for the future
              </p>
              <p>
                For complex environments we can consult on the best ways to
                organize your apps, hosting, infrastructure and alerting. We can
                do a deep assessment to make sure you are watching the right
                metrics and learning about the problems you might face before
                they happen.
              </p>
            </div>
            <div className="col-12 col-md-6 my-4">
              <h2>Migrations to the Cloud</h2>
              <p>
                Lower your risk and increase the speed of your cloud migration
                with well planned and executed plan and execution using New
                Relic.
              </p>
              <p>
                729 Solutions has helped hundreds of customers wire up all their
                key SaaS tools with quality integrations. We can help you make
                New Relic a core part of growing your technology business and
                connect to any other API driven tool including escalations,
                reporting, alerts tools. As a certified Systems Integrator to
                New Relic, we will move the right data and build stable
                performant integrations.
              </p>
            </div>
            <div className="col-12 col-md-6 my-4">
              <h2>Integrations</h2>
              <p>
                729 can connect New Relic with any other development or DevOps
                tool so everything in your pipeline works seamlessly together.
              </p>
              <p>
                The low performance, increased risk and higher cost of
                traditional server-based applications and databases are making
                everyone move to the cloud. New Relic is an excellent steering
                wheel for these migrations. As the team that is supporting
                Expedia’s move to AWS, we can help you use New Relic to plan,
                migrate and run your migrations safely and efficiently. Learn
                about performance baselines, dependencies and make your
                migration a success.
              </p>
            </div>
            <div className="col-12 col-md-6 my-4">
              <h2>Training</h2>
              <p>
                729 can teach you how to create industry and business unit
                specific charts and dashboards to track KPIs and also train your
                technical team the right way to implement and engage with the
                entire New Relic toolset.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-5 my-5">
          <div className="row">
            <div className="col py-2">
              <Quote
                quote="We knew that we never had to scramble or panic because you guys
                were with us."
                name="Daniel Davis"
                company="Namecheap"
              />
            </div>
          </div>
        </div>
      </section>
      <NeedAQuote dark />
      <BlogFeed containerClassname="py-6" />
    </Layout>
  );
}

export default Index;
